.container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

}

.section{
    width: 30%;
    display: flex;
    flex-direction: column;
}